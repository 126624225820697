<template>
  <div class="home">
    <div class="head">
      <div class="headL">
        <img class="logo" src="@/assets/logo.png" alt="" />
        <div>奢哈哈</div>
      </div>
      <div class="headR">
        <a href="#page1" class="btn" :class="{ active: isPageIdx == 0 }"
          >关于我们</a
        >
        <a href="#page2" class="btn" :class="{ active: isPageIdx == 1 }"
          >产品介绍</a
        >
        <a href="#page3" class="btn" :class="{ active: isPageIdx == 2 }"
          >合作伙伴</a
        >
        <a href="#page4" class="btn" :class="{ active: isPageIdx == 3 }"
          >其它</a
        >
      </div>
    </div>
    <div class="headMb">
      <div class="headL">
        <img class="logo" src="@/assets/logo.png" alt="" />
        <div>奢哈哈</div>
      </div>
      <div class="headR">
        <div class="dl" @click="goDow">下载奢哈哈</div>
        <img
          class="menuIcon"
          @click="openMenu(true)"
          v-if="!openType"
          src="@/assets/icon_more@2x.png"
          alt=""
        />
        <img
          class="menuIcon"
          @click="openMenu(false)"
          v-else
          src="@/assets/icon_close_white@2x.png"
          alt=""
        />
      </div>
    </div>
    <div class="menuList" v-if="openType">
      <a href="#page1" @click="openType = false">关于我们</a>
      <a href="#page2" @click="openType = false">产品服务</a>
      <a href="#page3" @click="openType = false">产品介绍</a>
      <a href="#page4" @click="openType = false">其它</a>
    </div>
    <full-page ref="fullpage" :options="options" id="fullpage">
      <div class="section banner">
        <div class="text fadeInUp">
          <div class="title animated">
            奢哈哈一款专为奢侈品行业量身打造的APP
          </div>
          <div class="info animated">
            平台提供：求购商机、进销存管理、线上贸易、行情查询、图片兜底等服务
          </div>

          <div class="titleMb">奢哈哈</div>
          <div class="titleMb">一款专为奢侈品行业量身打造的APP</div>
          <div class="infoMb">
            平台提供：求购商机、进销存管理、线上贸易、行情查询、图片兜底等服务
          </div>
        </div>
      </div>
      <div class="section describe">
        <div class="describeBg">
          <div class="descrSb">
            <div class="title animated" :class="{ fadeInUp: isPageIdx == 1 }">
              专为奢侈品行业量身打造
            </div>
            <div class="info animated" :class="{ fadeInUp: isPageIdx == 1 }">
              通过云计算，大数据分析全网行情趋势，以及对店铺数据整合分析；为商家提供运营支撑，并持续赋能。致力于打造二奢行业完整生态，实现全链路闭环。
            </div>
          </div>
        </div>
        <div class="describeBgMb">
          <div class="title">更好用的二奢进销存平台</div>
          <div class="info">
            通过云计算，大数据分析全网行情趋势，以及对店铺数据整合分析；为商家提供运营支撑，并持续赋能。致力于打造二奢行业完整生态，实现全链路闭环。
          </div>
        </div>
      </div>
      <div class="section imgs">
        <div class="title">产品介绍</div>
        <div class="imgCenter">
          <div class="imgItem">
            <div class="text">
              <div class="title1">线上贸易</div>
              <div class="info1">海量商家，轻松淘货</div>
            </div>
            <img src="@/assets/cc1@2x.jpg" alt="" />
          </div>
          <div class="imgItem">
            <div class="text">
              <div class="title1">商机线索</div>
              <div class="info1">在线求购，更多商机</div>
            </div>
            <img src="@/assets/cc2@2x.jpg" alt="" />
          </div>
          <div class="imgItem">
            <div class="text">
              <div class="title1">行情走势</div>
              <div class="info1">价格变动，实时更新</div>
            </div>
            <img src="@/assets/cc3@2x.jpg" alt="" />
          </div>
          <div class="imgItem">
            <div class="text">
              <div class="title1">在线沟通</div>
              <div class="info1">在线交流，快速成交</div>
            </div>
            <img src="@/assets/cc4@2x.jpg" alt="" />
          </div>
          <div class="imgItem">
            <div class="text">
              <div class="title1">进销存管理</div>
              <div class="info1">采购入库，成交开单</div>
            </div>
            <img src="@/assets/cc5@2x.jpg" alt="" />
          </div>
          <div class="imgItem">
            <div class="text">
              <div class="title1">数据统计</div>
              <div class="info1">店铺经营，尽在掌握</div>
            </div>
            <img src="@/assets/cc4@2x.jpg" alt="" />
          </div>
        </div>
        <swiper :slides-per-view="1" class="swiperCent">
          <swiper-slide>
            <div class="imgFlex">
              <div class="imgCent">
                <img
                  class="imgItem"
                  src="@/assets/png_introduce_1@2x.jpg"
                  alt=""
                />
                <div class="text">
                  <div class="title">线上贸易</div>
                  <div class="info">海量商家，轻松淘货</div>
                </div>
              </div>
              <div class="imgCent">
                <img
                  class="imgItem"
                  src="@/assets/png_introduce_2@2x.jpg"
                  alt=""
                />
                <div class="text">
                  <div class="title">商机线索</div>
                  <div class="info">在线求购，更多商机</div>
                </div>
              </div>
              <div class="imgCent">
                <img
                  class="imgItem"
                  src="@/assets/png_introduce_3@2x.jpg"
                  alt=""
                />
                <div class="text">
                  <div class="title">行情走势</div>
                  <div class="info">价格变动，实时更新</div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="imgFlex">
              <div class="imgCent">
                <img
                  class="imgItem"
                  src="@/assets/png_introduce_4@2x.jpg"
                  alt=""
                />
                <div class="text">
                  <div class="title">在线沟通</div>
                  <div class="info">在线交流，快速成交</div>
                </div>
              </div>
              <div class="imgCent">
                <img
                  class="imgItem"
                  src="@/assets/png_introduce_5@2x.jpg"
                  alt=""
                />
                <div class="text">
                  <div class="title">进销存管理</div>
                  <div class="info">采购入库，成交开单</div>
                </div>
              </div>
              <div class="imgCent">
                <img
                  class="imgItem"
                  src="@/assets/png_introduce_6@2x.jpg"
                  alt=""
                />
                <div class="text">
                  <div class="title">数据统计</div>
                  <div class="info">店铺经营，尽在掌握</div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="section er">
        <div class="erCenter">
          <img src="@/assets/qrcode.png" class="erCode" />
          <div class="text">
            <div class="title">更好用的二奢进销存平台</div>
            <div class="info">
              求购商机 · 进销存管理 · 线上贸易 · 行情查询 · 图片兜底
            </div>
          </div>
        </div>
        <div class="erCenterMb">
          <div class="text">
            <div class="title">更好用的二奢进销存平台</div>
            <div class="info">
              求购商机 · 进销存管理 · 线上贸易 · 行情查询 · 图片兜底
            </div>
          </div>
          <img src="@/assets/qrcode1.png" class="erCode" />
        </div>
        <div class="erBt">
          <div class="erBtCenter">
            <div class="top">
              <div class="cooperate">
                <div>合作伙伴：</div>
                <img class="itemImg1" src="@/assets/png_aliyun@2x.png" alt="" />
                <img
                  class="itemImg2"
                  src="@/assets/png_tengxunyun@2x.png"
                  alt=""
                />
                <img
                  class="itemImg3"
                  src="@/assets/png_zhifubao@2x.png"
                  alt=""
                />
              </div>
              <div class="email">
                <div>邮箱：nlyxappleid@163.com</div>
                <div class="icons">
                  <div class="wxicon"></div>
                  <img
                    class="kfer"
                    src="@/assets/png_customer_service@2x.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="bot">
              Copyright © 2023能力有限(河南)科技有限公司
              <a
                class="acHover"
                href="https://beian.mps.gov.cn/#/query/webSearch"
                >豫ICP备2023019098号-2号</a
              >
            </div>
          </div>
        </div>
        <div class="erBtMb">
          <div class="Partners">
            <div class="PartnersTitle">合作伙伴</div>
            <div class="PartnersImg">
              <img class="img1" src="@/assets/png_aliyun@2x1.png" alt="" />
              <img class="img1" src="@/assets/txy.png" alt="" />
              <img class="img2" src="@/assets/zfb.png" alt="" />
            </div>
          </div>
          <div class="emailMb">
            <div class="emailL">
              <div>邮箱:</div>
              <div>nlyxappleid@163.com</div>
            </div>
            <div class="emailR">
              <img src="@/assets/png_meimei@2x.png" alt="" />
              <div>奢哈哈企业微信</div>
            </div>
          </div>
          <div class="copyright">
            <div>Copyright © 2023能力有限(河南)科技有限公司</div>
            <a href="https://beian.mps.gov.cn/#/query/webSearch"
              >豫ICP备2023019098号-2号</a
            >
          </div>
        </div>
      </div>
    </full-page>
  </div>
</template>

<script setup>
import { ref } from "vue";
// import axios from "axios";
// Import Swiper Vue.js components
// import wx from "weixin-js-sdk";
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";

let isPageIdx = ref(0);
const fullpage = ref();
let openType = ref(false);
const ispage = (origin, destination) => {
  isPageIdx.value = destination.index;
};

// onMounted(() => {
//   axios
//     .post("http://114.117.200.34:8087/v1/common/get/wxSign", {
//       url: window.location.href,
//     })
//     .then((res) => {
//       if (res.code == 200) {
//         console.log(res);
//         // 调用微信的config接口
//         wx.config({
//           debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
//           appId: "", // 必填，公众号的唯一标识
//           timestamp: "", // 必填，生成签名的时间戳
//           nonceStr: "", // 必填，生成签名的随机串
//           signature: "", // 必填，签名
//           jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
//         });
//       }
//     });

//   // 配置成功后的回调函数
//   wx.ready(function () {
//     wx.updateAppMessageShareData({
//       title: "奢哈哈", // 分享标题
//       desc: "更好用的二奢进销存平台", // 分享描述
//       // link: '', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//       imgUrl: "../assets/logo.png", // 分享图标
//       success: function () {
//         // 设置成功
//       },
//     });

//     wx.updateTimelineShareData({
//       title: "奢哈哈", // 分享标题
//       desc: "更好用的二奢进销存平台", // 分享描述
//       imgUrl: "../assets/logo.png", // 分享图标
//       success: function () {
//         // 设置成功
//       },
//     });
//   });

//   // 配置失败的回调函数
//   wx.error(function (res) {
//     // 配置失败会执行error函数，如签名错误、兼容性错误等
//     console.log("配置失败", res);
//   });
// });

let options = ref({
  licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
  anchors: ["page1", "page2", "page3", "page4"],
  css3: true,
  onLeave: ispage,
});

const openMenu = (types) => {
  openType.value = types;
};

const goDow = () => {
  window.location = "https://h5.shehaha.cn/download";
};
</script>
<style lang="scss" scoped>
.head {
  position: fixed;
  top: 0;
  left: 0;
  color: #fff;
  width: 100%;
  z-index: 999;
  display: flex;
  padding: 10px 240px;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  // transition: background 1s;
  background: rgba($color: #101013, $alpha: 0.5);
  .headL {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    .logo {
      width: 30px;
      height: 30px;
      font-size: 18px;
      margin-right: 8px;
    }
  }
  .headR {
    display: flex;
    font-size: 14px;
    .btn {
      margin-right: 30px;
      cursor: pointer;
    }
    .btn:nth-child(4) {
      margin-right: 0px;
    }
    .active {
      font-weight: bold;
    }
  }
}
.bg {
}
.banner {
  position: relative;
  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    width: 100%;
    .title {
      font-size: 48px;
      margin-bottom: 30px;
      font-weight: bold;
    }
    .info {
      font-size: 32px;
    }
    .titleMb {
      font-size: 20px;
      font-weight: 600;
    }
    .infoMb {
      font-size: 12px;
      width: 343px;
      margin: 12px auto 0;
    }
  }
}
.describe {
  padding: 80px 106px;
  box-sizing: border-box;
  .describeBg {
    background: url("@/assets/png_2@2x.png") no-repeat center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;
    .descrSb {
      position: absolute;
      top: 40%;
      left: 10%;
      text-align: left;
      width: 32%;
      .title {
        font-size: 36px;
        margin-bottom: 32px;
        font-weight: 600;
        color: #005550;
        display: inline-flex;
      }
      .info {
        font-size: 18px;
        display: inline-flex;
        color: #000;
      }
    }
  }
}

.describeBgMb {
  background: url("@/assets/png_1@2x_1.png") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  .title {
    font-size: 20px;
    margin-bottom: 12px;
    color: #005550;
    margin-top: 60px;
    font-weight: 600;
  }
  .info {
    font-size: 12px;
    width: 300px;
    text-align: center;
    color: #000;
  }
}

.imgs {
  padding: 75px 106px;
  box-sizing: border-box;
  .title {
    color: #005550ff;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 50px;
  }
  .imgCenter {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    .imgItem {
      width: 33%;
      margin-bottom: 10px;
      position: relative;
      img {
        width: 100%;
        display: block;
      }
      .text {
        position: absolute;
        top: 40px;
        left: 40px;
        text-align: left;
        .title1 {
          font-weight: 600;
          color: #ffffff;
          font-size: 26px;
          margin-bottom: 12px;
        }
        .info1 {
          color: #ffffff;
          font-size: 16px;
        }
      }
    }
    .imgItem:nth-child(n + 4) {
      margin-bottom: 0;
    }
  }
  .imgFlex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .imgCent {
      margin-bottom: 12px;
      position: relative;
      .imgItem {
        width: 100%;
      }
      .text {
        position: absolute;
        top: 20px;
        left: 20px;
        text-align: left;
        .title {
          color: #fff;
          font-size: 20px;
          margin-bottom: 5px;
        }
        .info {
          color: #fff;
          font-size: 12px;
        }
      }
    }
    .imgCent:nth-child(3) {
      .imgItem {
        margin-bottom: 0;
      }
    }
  }
}
.er {
  background: #f6f7fb;
  position: relative;
  .erCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30vh;
    .erCode {
      width: 200px;
      height: 200px;
      margin-right: 100px;
    }
    .text {
      .title {
        font-size: 32px;
        margin-bottom: 30px;
        font-weight: 600;
        color: #005550;
      }
      .info {
        font-size: 20px;
        color: #1d1d1f;
      }
    }
  }
  .erCenterMb {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 25%;
    .erCode {
      width: 168px;
      height: 168px;
      margin-top: 24px;
    }
    .text {
      .title {
        font-size: 20px;
        margin-bottom: 12px;
        font-weight: 600;
        color: #005550;
      }
      .info {
        font-size: 12px;
        color: #1d1d1f;
      }
    }
  }
  .erBt {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999999;
    font-size: 14px;
    padding: 35px 240px 25px;
    box-sizing: border-box;
    .erBtCenter {
      width: 100%;
      .top {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-bottom: 35px;
        border-bottom: 1px solid #202020;
        margin-bottom: 20px;
        .cooperate {
          display: flex;
          align-items: center;
          .itemImg1 {
            width: 50px;
            margin-right: 16px;
          }
          .itemImg2 {
            width: 52px;
            margin-right: 16px;
          }
          .itemImg3 {
            width: 46px;
          }
        }
        .email {
          display: flex;
          align-items: center;
          .icons {
            position: relative;
            .wxicon {
              width: 24px;
              height: 24px;
              margin-left: 40px;
              background: url("@/assets/icon_wechat@2x.png") no-repeat;
              background-size: cover;
              cursor: pointer;
            }
            .wxicon:hover {
              background: url("@/assets/icon_wechat_click@2x.png") no-repeat;
              background-size: cover;
            }
            .wxicon:hover + .kfer {
              display: block;
            }
            .kfer {
              position: absolute;
              width: 100px;
              top: -110px;
              right: -30px;
              display: none;
            }
          }
        }
      }
      .bot {
        text-align: center;
        color: #666;
      }
    }
  }
  .erBtMb {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000000;
    display: flex;
    color: #999999;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    .Partners {
      width: 100%;
      padding-bottom: 18px;
      border-bottom: 1px solid #202020;
      box-sizing: border-box;
      .PartnersTitle {
        font-size: 20px;
        text-align: center;
        margin-bottom: 23px;
      }
      .PartnersImg {
        display: flex;
        justify-content: space-between;
        .img1 {
          width: 72px;
        }
        .img2 {
          width: 52px;
        }
      }
    }
    .emailMb {
      height: 117px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      border-bottom: 1px solid #202020;
      .emailL {
        text-align: left;
        font-size: 12px;
      }
      .emailR {
        text-align: center;
        font-size: 10px;
        img {
          width: 42px;
          height: 42px;
          margin-bottom: 6px;
        }
      }
    }
    .copyright {
      display: flex;
      justify-content: space-around;
      height: 39px;
      align-items: center;
      font-size: 8px;
      color: #666;
    }
  }
}

.headMb {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  color: #fff;
  width: 100%;
  z-index: 999;
  background: rgba($color: #101013, $alpha: 0.5);
  .headL {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    .logo {
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
  }
  .headR {
    display: flex;
    align-items: center;
    height: 44px;
    font-size: 12px;
    .dl {
      font-size: 12px;
      border: 1px solid #ffffff;
      width: 102px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      margin-right: 16px;
      font-weight: 600;
    }
    .menuIcon {
      width: 16px;
      height: 16px;
    }
  }
}
.menuList {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 76px;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: rgba(16, 16, 19, 1);
  color: #fff;
  z-index: 998;
  font-size: 20px;
  a {
    margin-bottom: 32px;
  }
}
:deep(.fp-overflow) {
  height: 100%;
}
.acHover:hover {
  color: #999;
}
@media screen and (max-width: 980px) {
  .head {
    display: none;
  }
  .headMb {
    display: flex;
  }
  .banner {
    background: url("@/assets/png_1@2x_1 (2).png") no-repeat center;
    background-size: cover;
    .title,
    .info {
      display: none;
    }
    .titleMb,
    .infoMb {
      display: block;
    }
  }
  .describe {
    padding: 68px 20px 20px 20px !important;
  }
  .describeBg {
    display: none !important;
  }
  .describeBgMb {
    display: flex !important;
  }
  .imgs {
    padding: 60px 20px 20px 20px;
    .title {
      font-size: 20px;
      margin-bottom: 16px;
    }
    .imgCenter {
      display: none;
    }
    .swiperCent {
      display: block;
    }
  }
  .erCenter {
    display: none !important;
  }
  .erCenterMb {
    display: flex !important;
  }
  .erBt {
    display: none !important;
  }
  .erBtMb {
    display: flex !important;
  }
}
@media screen and (min-width: 980px) {
  .head {
    display: flex;
  }
  .headMb {
    display: none;
  }
  .banner {
    background: url("@/assets/png_1.jpg") no-repeat center;
    background-size: cover;
    .title,
    .info {
      display: block;
    }
    .titleMb,
    .infoMb {
      display: none;
    }
  }
  .describe {
    padding: 80px 106px !important;
  }
  .describeBg {
    display: flex !important;
  }
  .describeBgMb {
    display: none !important;
  }
  .imgs {
    padding: 75px 106px;
    .title {
      font-size: 30px;
    }
    .imgCenter {
      display: flex;
    }
    .swiperCent {
      display: none;
    }
  }
  .erCenter {
    display: flex !important;
  }
  .erCenterMb {
    display: none !important;
  }
  .erBt {
    display: flex !important;
  }
  .erBtMb {
    display: none !important;
  }
}
</style>

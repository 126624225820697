import {  createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css/animate.min.css'

import 'vue-fullpage.js/dist/style.css'
// import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
import VueFullPage from 'vue-fullpage.js'

createApp(App).use(store).use(VueFullPage).use(router).mount('#app')
